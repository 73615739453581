<template>
  <div class="misc-wrapper" >
    <!-- /Brand logo-->
    <b-link :to="{ name:'login' }" class="brand-logo">
      <h2 class="brand-text text-primary ml-1">
        FiqOn
      </h2>
      <b-img
        fluid
        class="fiqon-logo" 
        :src="logoImg"
        alt="Login V2"
      />
    </b-link>
    <!-- /Brand logo-->

    <!-- <div v-if="thirdPatyInfo && thirdPatyInfo.timer" class="oauth-limit-timer">
      <div>
        <div align="center" class="small  text-secondary">this page will expire</div>
        <h4>
          Time Limit of <b class="text-success">{{thirdPatyInfo.timer}} seconds</b>
        </h4>

        <div class="clock-outline">
          <div align="center">
            <div class="clock-container">
              <div/> <div class="clock-pointer"/>
            </div>
          </div>
        </div>


      </div>
    </div> -->

    <main class="main-container" v-if="thirdPatyInfo" >
      <b-overlay :show="loadingInstallation" variant="none" blur="1px">

        <!-- <template #overlay>
          <b-icon icon="check2" scale="4" variant="success"/>
        </template> -->

        <h4><span class="font-weight-bolder">{{thirdPatyInfo.label}}</span> OAuth Authentification</h4>
        <b-card class="m-0">

        <!-- ================= LOGIN ================== -->
          <b-collapse :visible="!userLoggedIn" appear>
          <div>
            <b-col cols="12" class="px-xl-2 mx-auto">

              <b-card-text class="mb-2 font-weight-bolder">
                {{ t('login.login-data') }}
              </b-card-text>

              <!-- form -->  
              <validation-observer ref="loginValidation">
                <b-form class="auth-login-form mt-2" @submit.prevent>
                  <!-- username -->
                  <b-form-group
                    :label="t('login.username')"
                    label-for="register-username"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="t('login.username-validator')"
                      vid="username"
                      rules="required"
                    >
                      <b-form-input
                        id="register-username"
                        v-model="username"
                        name="register-username"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Username"
                      />
                      <small name="username" class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- forgot password -->
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label for="login-password">{{ t('login.password')}}</label>
                      <b-link :to="{ name: 'forgot-password' }">
                        <small>{{ t('login.forgot-password')}}</small>
                      </b-link>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      :name="t('login.password-validator')"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="login-password"
                          v-model="password"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          name="login-password"
                          placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- submit buttons -->
                  <b-button
                    type="submit"
                    variant="primary"
                    block
                    @click="validationForm"
                  >
                    {{ t('login.sign-in')}}
                  </b-button>
                </b-form>
              </validation-observer>

              <b-card-text class="text-center mt-2">
              </b-card-text>

              <!-- divider -->
              <div class="divider my-2">
                <b-link href="https://fique.online" target="_blank" class="divider-text"> fique.online </b-link>
              </div>

            </b-col>
          </div>
          </b-collapse>
        <!-- =============== END OF LOGIN ================== -->

        <b-collapse :visible="userLoggedIn" appear @shown="fetchOrganizations()">
          <template v-if="getLoggedUser">
            <h5 class="text-center highlighted-user-name">
            Logged in as: <span class="font-weight-bolder">{{getLoggedUser.username}}</span>
            </h5>
          </template>

          <p class="small text-secondary font-weight-bold">Select an Organization and a Transmission to vinculate this App</p>

          <div class="mb-2">
            <label>
              Organization
              <b-spinner small class="ml-50" variant="secondary" v-if="loadingOrganizations"/>
            </label>
            <v-select v-model="selectedOrganization" :clearable="false" :options="orgOptions" :disabled="loadingOrganizations || !userLoggedIn" @input="fetchTransmissions()">
              <template #selected-option="data">
                <div>
                  {{data.name}}
                </div>
              </template>


              <template #option="data">
                <div >
                  {{data.name}}
                </div>
              </template>


            </v-select>
          </div>
          
          <div class="">
            <label>
              Transmission
              <b-spinner small class="ml-50" variant="secondary" v-if="loadingTransmissions"/>
            </label>
            <v-select :disabled="!selectedOrganization || loadingTransmissions" v-model="selectedTransmission" :clearable="false" :options="transmissionOptions">
              <template #selected-option="data">
                <div>
                  {{ data.name}}
                </div>
              </template>
        
              <template #option="data">
                <div :id="'select-transmission-option-'+data.id">
                  {{ data.name}}
                </div>
                <b-tooltip :target="'select-transmission-option-'+data.id" placement="right" :boundary-padding="500">
                  <div class="preview-transmission-background" :style="`background-image: url('https://picsum.photos/seed/${data.identifier}/400/100');`"></div>
                </b-tooltip>
              </template>

            </v-select>
          </div>


        </b-collapse>


        <div align="right" class="mt-3">
          <b-button variant="purple" class="py-50 px-1" :disabled="!selectedOrganization || !selectedTransmission" @click="commitAppInstallation()">
            <template v-if="!userLoggedIn">
              Proceed
              <b-icon icon="arrow-right-circle-fill" class="ml-25"/>
            </template>

            <template v-else>
              Vinculate Account
              <b-icon icon="arrow-right-circle-fill" class="ml-25"/>
            </template>
          </b-button>
        </div>

        </b-card>
      </b-overlay>
    </main>

  </div>
</template>

<script>
import OAuthTypes from '@/custom/class/Enum/Map/OAuthTypes.js'

import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BCard,
  BCollapse,
  BSpinner,
  BOverlay,
  BTooltip
} from "bootstrap-vue";

import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import store from "@/store/index";
import Animation from "/src/layouts/components/Animation.vue";
import { makeToast } from "@/layouts/components/Popups";
import custom from "@/custom";
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import Locale from '@/layouts/components/Locale.vue'
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import { successToast } from '@/custom/class/FunctionClasses/CommonToasts';


export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    Animation,
    ValidationProvider,
    ValidationObserver,
    BCard,
    // Toogle colors (light/dark)
    DarkToggler,
    Locale,
    BCollapse,
    vSelect,
    BSpinner,
    BOverlay,
    BTooltip
  },
  mixins: [togglePasswordVisibility],
  data() {
    const { t } = useI18nUtils()
    return {
      logoImg: require('@/assets/images/logo/logo.svg'),
      status: "",
      password: "",
      username: "",
      logoImg: require("@/assets/images/logo/logo.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      t,
      validationRefs: ['username', 'password'],



      thirdPatyInfo: undefined,

      userLoggedIn: false,
      selectedOrganization: undefined,
      selectedTransmission: undefined,

      orgOptions: undefined,
      transmissionOptions: undefined,

      loadingOrganizations: false,
      loadingTransmissions: false,

      loadingInstallation: false,
    }
  },
  computed: {
    ...mapGetters(['getLoggedUser']),
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    logoUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      
      {
        let oauthIdentifier = this.$route.params.thirdPatyIdentifier
        let info = new OAuthTypes().items[oauthIdentifier]
        
        if (!info){
          this.$router.push({name:'error-404'});
        }
        this.thirdPatyInfo = info
      }

      if (localStorage.getItem('sessionKey')){
        this.userLoggedIn = true
      }


    },
    async validationForm() {
      await this.rememberMe()
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.$store
            .dispatch("loginVerification", {
              username: this.username,
              password: this.password,
            })
            .then((response) => {
              
              let userData = response.data.data.user;
              
              this.updateAbility(userData.enum_access_level)

              switch (true) {
                case !userData.email_verified: {
                  this.$router.push({ name: "verify-email" }).then(() => {
                    makeToast(custom.infoMessages.verify_email);
                  });
                  break;
                }
                case !userData.phone_verified: {
                  this.$router.push({ name: "verify-phone" }).then(() => {
                    makeToast(custom.infoMessages.verify_phone);
                  });
                  break;
                }
                default: {
                  this.userLoggedIn = true;
                  successToast({
                    text: 'Successfully Logged in.'
                  })
                  // this.$router.push({ name: "home" }).then(() => {
                  //   makeToast(custom.successMessages.home);
                  // });
                }
              }
            })
            .catch((error) => {
              
              let errorType = error.unique_code;
              
              if (errorType == "002") {
                this.$router
                  .replace({ name: "access-point-validation" })
                  .then(() => {
                    makeToast(custom.errorMessages[errorType]);
                  });
              } else {
                makeToast({
                  title: this.$t("login.toast.login.error.title"),
                  text: this.$t("login.toast.login.error.message"),
                  variant: "danger",
                  icon: "AlertCircleIcon",
                });
              }
            });
        // }else{
        //   this.validationRefs.forEach(element => { 
        //     this.$refs[element].applyResult({
        //       errors: ["this is a backend error"], // array of string errors
        //       valid: false, // boolean state
        //       failedRules: {} // should be empty since this is a manual error.
        //     });
        //   }) 
        }
      });
    },
    rememberMe(){
      if (this.status == true) {
        localStorage.setItem('storedRememberMe', this.username)
      }
    },
    checkRememberMe(){
      let rememberMe = localStorage.getItem('storedRememberMe')
      if (rememberMe) {
        this.username = rememberMe
      }
    },
    updateAbility(accessLevel) {
      if (accessLevel.label != 'GOD') {
        this.$ability.update([
          {
            action: 'manage',
            subject: 'all',
          },
        ])
      } else {
        this.$ability.update([
          {
            action: 'read',
            subject: 'all',
          },
        ])
      }
    },
    fetchOrganizations(){
      this.selectedOrganization = undefined
      this.loadingOrganizations = true

      this.$store.dispatch("getOrganizationByLoggedUser").then((resp)=>{
        this.orgOptions = resp
      })
      .finally(()=>{
        this.loadingOrganizations = false
      })
    },
    fetchTransmissions(){
      this.selectedTransmission = undefined
      this.loadingTransmissions = true
      this.$store.dispatch('getTransmissionByOrganization',{ organization: this.selectedOrganization.id})
      .then((resp)=>{
        this.transmissionOptions = resp
      })
      .finally(()=>{
        this.loadingTransmissions = false
      })
      .catch((err)=>{
        console.error(err)
      })
    },
    commitAppInstallation(){
      console.log('%c'+('COMITTING!'), 'color: goldenrod');
      this.loadingInstallation = true
      
    }
    
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';

h4{
  font-weight: normal;
}

.main-container{
  --default-width: 550px;

  width:  var(--default-width);
  max-width: var(--default-width);
  margin: auto auto auto auto;
  padding-bottom: 200px;
}

.highlighted-user-name{
  padding: 10px;
  background-color: var(--purple);
  color: var(--light) !important;
  margin: 0 0 15px 0px;
  border-radius: 5px;
}

.oauth-limit-timer{
  position: absolute;
  right: 30px;
  top: 30px
}
</style>

<style lang="scss" scoped>

.preview-transmission-background{
  width: 200px;
  height: 50px;
  background-color: black;
  border-radius: 5px;
  background-position: center;
  background-size: cover;
}

@keyframes rotate-clock {
  0%{
    //box-shadow: inset 0 0 20px var(--success);
    rotate: 0deg;
  }
  50%{
    //box-shadow: inset 0 0 20px var(--warning);
    rotate: 180deg;
  }
  100%{
    //box-shadow: inset 0 0 20px var(--danger);
    rotate: 360deg;
  }
}

.clock-outline{
  rotate: -90deg;
}
.clock-container{
  border: 3px solid var(--light);
  border-radius: 2000px;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);

  box-shadow: inset 0 0 0 10px rgba(0, 0, 0, 0.1);

  animation-name: rotate-clock;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  div{
    width: 50%;
  }
  .clock-pointer{
    width: 50%;
    height: 3px;
    background-color: black;
    border-left: 3px solid white;
    box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.5);
    margin: 5px;
  }

}
</style>