export default class OAuthTypes {
  constructor() {
      this.items = {

          "conta-azul" : {
            label: "Conta Azul",
            timer: undefined
          },

          "todoist" : {
            label: "Todoist",
            timer: undefined,
          },

        };
  }
}